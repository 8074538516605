import React from 'react'
import { Layout, Stack, Hero, Main, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Seo from '@widgets/Seo'
import HeroComponent from '../components/Hero/Hero'

const Posts = ({ data: { paginatedPosts = {}, posts }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props

  const page = paginatedPosts.pageInfo?.currentPage
  const title = page > 1 ? `Sheet Gurus SMS Blog - Page ${page}` :  'Sheet Gurus SMS Blog'

  const descriptions = [
    "Unlock expert advice on enhancing your SMS campaigns using Sheet Gurus SMS integrated with Google Sheets. Our blog offers practical guides and industry insights to improve your communication strategies.",
    "Stay ahead in SMS marketing with the Sheet Gurus SMS Blog. Explore articles on integrating SMS with Google Sheets, best practices, and success stories to elevate your outreach.",
    "Discover innovative ways to utilize Sheet Gurus SMS through our blog. From educational use cases to small business tips, learn how to maximize your messaging potential using Google Sheets.",
    "Navigate the world of SMS communication with confidence. The Sheet Gurus SMS Blog provides resources and tutorials on leveraging Google Sheets for effective audience engagement.",
    "Enhance your messaging strategies with insights from the Sheet Gurus SMS Blog. Access expert content on SMS best practices, integration techniques with Google Sheets, and industry trends.",
  ]

  const description = descriptions[page - 1] || descriptions[0]

  return (
    <Layout {...props}>
      <Seo title={title} description={description} />
      <Hero>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main sx={{ zIndex: 1 }}>
          <CardList
            variant={['horizontal']}
            nodes={paginatedPosts.nodes}
            columns={[1, 2]}
            omitMedia
          />
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
      <Divider space={5} />
      <Stack>
        <Main sx={{ zIndex: 1 }}>
          {services.mailchimp && <NewsletterExpanded simple />}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
